import { Badge, BadgeProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
	'& .MuiBadge-badge': {
		right: 10,
		top: -25,
		border: `2px solid ${theme.palette.background.paper}`,
		padding: '0px 8px',
	},
}));

interface IReverseBadgeProps {
	children: ReactNode;
	isReverse: boolean;
}

const ReverseBadge = ({ children, isReverse }: IReverseBadgeProps) => (
	<StyledBadge
		badgeContent="Reversa"
		color="secondary"
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		invisible={!isReverse}
	>
		{children}
	</StyledBadge>
);
export default ReverseBadge;
