import './global.css';
import AppProvider from './hooks';
import { AppRoutes } from './routes';
import { isMockApiEnabled } from './services/api';
import { makeServer } from './services/mirage';

if (isMockApiEnabled) {
	makeServer();
}

const App = () => (
	<AppProvider>
		<AppRoutes />
	</AppProvider>
);

export default App;
