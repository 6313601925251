import axios from 'axios';
import { local, session } from '../utils/storage';

export const isMockApiEnabled =
	process.env.REACT_APP_MOCK_API_ENABLED === 'true';

const validateStatus = (status: number) => {
	if (status === 401) {
		local.clear();
		session.clear();
		window.location.assign('/');
	}
	return status >= 200 && status < 300;
};

const URL = process.env.REACT_APP_API_URL;

const mockApiURL = `${window.origin}/api`;

const baseURL = isMockApiEnabled ? mockApiURL : URL;

const api = axios.create({
	baseURL,
	validateStatus,
});

export default api;
