import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useGeolocated } from 'react-geolocated';
import { useDispatch } from 'react-redux';
import { useAuth } from '../../../hooks/auth';
import { addHistoryItem } from '../../../store/historySlice';
import { enqueuePendingRequest } from '../../../store/requestQueueSlice';
import { sendRequest } from '../../../utils/driverActions';
import { logError } from '../../../utils/error';
import { queryClient } from '../../../services/queryClient';
import { IDriverPackages, IPackage } from '../../../DTOs';

interface OccurrenceDialogProps {
	occurrencePackage: string | null;
	handleClose: () => void;
}

const URL = '/operations/occurrence';

const occurrences = [
	{
		id: '46',
		occurrence: 'Área de risco ou de difícil acesso',
	},
	{
		id: '48',
		occurrence: 'Carga recusada pelo destinatário',
	},
	{
		id: '47',
		occurrence: 'Desastre natural',
	},
	{
		id: '43',
		occurrence: 'Destinatário ausente',
	},
	{
		id: '38',
		occurrence: 'Destinatário faleceu ou faliu',
	},
	{
		id: '45',
		occurrence: 'Destinatário mudou de endereço',
	},
	{
		id: '44',
		occurrence: 'Destinatário sem documento',
	},
	{
		id: '39',
		occurrence: 'Endereço não localizado',
	},
];

const OccurrenceDialog = ({
	occurrencePackage,
	handleClose,
}: OccurrenceDialogProps) => {
	const [occurrence, setOccurrence] = useState<string | null>(null);

	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const { user } = useAuth();

	const { coords, isGeolocationAvailable, isGeolocationEnabled } =
		useGeolocated({
			positionOptions: {
				enableHighAccuracy: false,
			},
			userDecisionTimeout: 5000,
		});

	const { mutate: occurrenceMutation } = useMutation(
		async () => {
			if (!occurrence || !occurrencePackage) return;

			if (!isGeolocationAvailable) {
				enqueueSnackbar('Localização não disponível', {
					variant: 'warning',
				});
			}

			if (!isGeolocationEnabled) {
				enqueueSnackbar('Localização bloqueada', {
					variant: 'warning',
				});
			}

			let coordinates = null as string | null;

			if (coords) {
				coordinates = `(${coords.latitude},${coords.longitude})`;
			}

			const packages = [
				{
					term: occurrencePackage,
					data: {
						occurrence,
						coordinates,
					},
				},
			];

			if (!navigator.onLine) {
				dispatch(
					enqueuePendingRequest({
						body: packages,
						userId: user.id,
						url: URL,
					})
				);
			} else {
				try {
					await sendRequest({
						packages,
						url: URL,
					});

					dispatch(
						addHistoryItem({
							type: 'ocurrence',
							data: packages,
						})
					);
				} catch (error) {
					logError(error);
					enqueueSnackbar(`Erro lançar ocorrência, tente novamente!`, {
						variant: 'error',
					});
				}
			}
		},
		{
			onSuccess: async () => {
				enqueueSnackbar(`Ocorrência lançada com sucesso!`, {
					variant: 'success',
				});

				const collectQuery = `/drivers/${user.id}/collect`;

				queryClient.setQueryData(
					[collectQuery],
					(oldPackages: IDriverPackages | undefined) =>
						oldPackages && {
							driverId: oldPackages.driverId,
							packages: oldPackages.packages.filter(
								(p) => p.alphaCode !== occurrencePackage
							) as IPackage[],
						}
				);

				await queryClient.invalidateQueries([collectQuery]);
			},
			onError: (error) => {
				logError(error);
				enqueueSnackbar(
					`Houve um erro ao registrar a ocorrênca, tente novamente`,
					{
						variant: 'error',
					}
				);
			},
		}
	);

	async function handleReportOccurrence() {
		handleClose();
		occurrenceMutation();
	}

	return (
		<Dialog
			open={!!occurrencePackage}
			onClose={() => handleClose()}
			aria-labelledby="occurrence-dialog-title"
			aria-describedby="occurrence-dialog-description"
		>
			<DialogTitle>Reportar ocorrência</DialogTitle>
			<DialogContent>
				<DialogContentText id="occurrence-dialog-description">
					<Stack spacing={2}>
						<Typography fontSize="1.2rem"># {occurrencePackage}</Typography>
						<Typography fontSize="1.2rem">
							Selecione o tipo para continuar
						</Typography>

						<FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">Ocorrência</InputLabel>
							<Select
								name="occurrences"
								id="occurrences"
								label="Tipo de ocorrência"
								onChange={(e) => setOccurrence(String(e.target.value))}
							>
								{occurrences.map((o) => (
									<MenuItem value={o.id} key={o.id}>
										{o.occurrence}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Stack>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => handleReportOccurrence()}
					disabled={!occurrence}
					variant="outlined"
				>
					Confirmar
				</Button>
				<Button onClick={() => handleClose()} variant="contained">
					Cancelar
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default OccurrenceDialog;
