import { Button, Modal } from '@mui/material';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import Sign from './Sign';
import api from '../../../services/api';
import { IDocument } from '../../../DTOs/IManifests';

interface IManifestActionsProps {
	manifestId: string;
	isSigned: boolean;
	manifestDocument: IDocument;
}

const ManifestActions = ({
	manifestId,
	isSigned,
	manifestDocument,
}: IManifestActionsProps) => {
	const [showSign, setShowSign] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const handleDownload = async (filename: string) => {
		try {
			const response = await api.get(`manifest/file/${filename}`, {
				responseType: 'blob',
			});

			if (response.status === 200) {
				const file = new Blob([response.data], {
					type: 'application/pdf',
				});

				const fileUrl = URL.createObjectURL(file);

				const downloadLink = document.createElement('a');
				downloadLink.href = fileUrl;
				downloadLink.download = filename;

				downloadLink.click();

				URL.revokeObjectURL(fileUrl);
				enqueueSnackbar(`Sucesso no download do arquivo!`, {
					variant: 'success',
				});
			}
		} catch (error) {
			enqueueSnackbar(`Erro ao fazer o download do arquivo!`, {
				variant: 'error',
			});
		}
	};

	return (
		<>
			<Modal
				open={!!showSign}
				onClose={() => setShowSign(false)}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Sign manifestId={manifestId} closeSignModal={setShowSign} />
			</Modal>
			<Button
				variant="outlined"
				fullWidth
				onClick={() => handleDownload(manifestDocument.fileName)}
			>
				Baixar Documento
			</Button>
			<Button
				variant="contained"
				fullWidth
				onClick={() => setShowSign(true)}
				sx={{ display: isSigned ? 'none' : 'block' }}
			>
				Assinar Documento
			</Button>
		</>
	);
};

export default ManifestActions;
