import { init as initApm } from '@elastic/apm-rum';
import packageJSON from '../../package.json';

const apm = initApm({
	// Defina o nome do serviço necessário (caracteres permitidos: a-z, A-Z, 0-9, -, _ e espaço)
	serviceName: packageJSON.name,
	// Defina a versão da sua aplicação
	// Usado no APM Server para encontrar o mapa de origem correto
	serviceVersion: packageJSON.version,
	// Definir URL customizado do APM Server (padrão: http://localhost:8200)
	serverUrl: process.env.REACT_APP_APM_SERVER_URL,
	// distributedTracingOrigins: ['http://localhost:8080'],
	environment: String(process.env.NODE_ENV),
});

export default apm;
