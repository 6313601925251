import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Modal,
	Select,
	Stack,
	Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { IModality } from '../../DTOs';

interface IDeliveryModalities {
	modalities: IModality[];
	handleSetModality: (modality: string) => void;
	signOut: () => void;
}

const DeliveryModalities = ({
	modalities,
	handleSetModality,
	signOut,
}: IDeliveryModalities) => {
	const [isOpen, setOpen] = useState(true);

	useEffect(() => {
		const timer = setTimeout(() => {
			setOpen(false);
		}, 10000);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	return (
		<Stack
			width="100vw"
			height="100vh"
			direction="column"
			justifyContent="center"
			alignItems="center"
			bgcolor="#1253da"
		>
			<Modal
				open={isOpen}
				onClose={() => setOpen(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Stack
					width="90vw"
					position="absolute"
					top="50%"
					left="50%"
					bgcolor="background.paper"
					boxShadow={24}
					borderRadius="16px"
					padding="16px"
					direction="column"
					justifyContent="center"
					alignItems="center"
					sx={{
						transform: 'translate(-50%, -50%)',
					}}
				>
					<img
						style={{
							borderRadius: '16px',
							border: '1px solid #fafafa',
							width: '100%',
						}}
						src="https://vf-store.nyc3.cdn.digitaloceanspaces.com/assets/images/banner_mobile_7_anos.png"
						alt="Aniversario VF"
					/>
				</Stack>
			</Modal>

			<Stack
				width="90%"
				height="fit-content"
				maxWidth="300px"
				sx={{
					background: '#fafafa',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					alignItems: 'center',
					borderRadius: '8px',
					padding: '16px',
				}}
				spacing="8px"
				gap="8px"
			>
				<Typography component="p" fontSize="1.5rem" textAlign="center">
					Selecione sua modalidade de entrega
				</Typography>

				<FormControl fullWidth>
					<InputLabel>Modalidade</InputLabel>
					<Select
						name="modalities"
						id="modalities"
						onChange={(e) => handleSetModality(String(e.target.value))}
						fullWidth
						label="Escolha aqui"
					>
						{!!modalities &&
							modalities.length > 0 &&
							modalities.map((m) => (
								<MenuItem value={m.id} key={m.id}>
									{m.label}
								</MenuItem>
							))}
					</Select>
				</FormControl>

				<Button onClick={() => signOut()} fullWidth variant="contained">
					Sair
				</Button>
			</Stack>
		</Stack>
	);
};

export default DeliveryModalities;
