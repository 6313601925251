import { Button, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { MouseEvent, ReactNode, useState } from 'react';

interface IConfirmationProps {
	trigger: ReactNode;
	action: () => void;
	text: string;
}

const Confirmation = ({
	trigger,
	action,
	text = 'Realmente deseja confirmar esta ação?',
}: IConfirmationProps) => {
	const [open, setOpen] = useState<boolean>(false);

	const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(
		null
	);

	const handleClick = (event: MouseEvent) => {
		setOpen(true);
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const handleAction = () => {
		setOpen(false);
		setAnchorEl(null);
		action();
	};

	return (
		<>
			<Box sx={{ width: 'fit-content' }} onClick={handleClick}>
				{trigger}
			</Box>
			<Popper open={open} anchorEl={anchorEl} placement="auto">
				<Box
					maxWidth="200px"
					bgcolor="#fafafa"
					border="1px solid rgba(0,0,0,0.2)"
					borderRadius="4px"
				>
					<Stack
						width="100%"
						direction="column"
						justifyContent="flex-start"
						alignItems="flex-start"
						spacing={2}
						padding="8px"
					>
						<Typography component="div">{text}</Typography>
						<Button onClick={() => handleAction()} variant="contained">
							Confirmar
						</Button>
					</Stack>
				</Box>
			</Popper>
		</>
	);
};

export default Confirmation;
