import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import SignatureCanvas from 'react-signature-canvas';
import { IDeliveryActions } from '../../../../../../DTOs';

interface SignatureProps {
	showSignModal: (close: boolean) => void;
}

const Signature: React.FC<SignatureProps> = ({ showSignModal }) => {
	const signatureRef = useRef<SignatureCanvas>(null);
	const { enqueueSnackbar } = useSnackbar();
	const { setValue } = useFormContext<IDeliveryActions>();

	const handleSign = () => {
		try {
			if (signatureRef.current && !signatureRef.current.isEmpty()) {
				const signatureData = signatureRef.current.toDataURL();
				setValue('receiver.signature', signatureData);
			}
		} finally {
			showSignModal(false);
			enqueueSnackbar(`Documento assinado com sucesso!`, {
				variant: 'success',
			});
		}
	};

	const handleClear = () => {
		if (signatureRef.current) {
			signatureRef.current.clear();
		}
	};

	return (
		<Stack
			component="div"
			width="100%"
			maxWidth="360px"
			maxHeight="600px"
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			spacing={2}
			sx={{
				background: 'white',
				borderRadius: '8px',
				padding: 4,
			}}
		>
			<Typography fontSize="0.8rem" fontWeight="300">
				Assine aqui...
			</Typography>
			<SignatureCanvas
				ref={signatureRef}
				canvasProps={{
					width: 400,
					height: 200,
					className: 'signature-canvas',
					style: { backgroundColor: 'white' },
				}}
				onBegin={() => {
					if (signatureRef.current) {
						const canvas = signatureRef.current.getCanvas();
						const context = canvas.getContext('2d');
						if (context) {
							context.globalCompositeOperation = 'source-over';
						}
					}
				}}
			/>
			<Divider sx={{ width: '100%' }} variant="middle" />

			<Box
				sx={{
					display: 'flex',
					width: '100%',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<Button variant="outlined" onClick={handleClear}>
					Limpar
				</Button>
				<Button variant="contained" onClick={() => handleSign()}>
					Salvar
				</Button>
			</Box>
		</Stack>
	);
};

export default Signature;
