import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

const createNoopStorage = () => ({
	getItem() {
		return Promise.resolve(null);
	},
	setItem(value: string) {
		return Promise.resolve(value);
	},
	removeItem() {
		return Promise.resolve();
	},
});

const storage =
	typeof window !== 'undefined'
		? createWebStorage('local')
		: createNoopStorage();

export default storage;
