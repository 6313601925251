import {
	Error,
	ExpandMore,
	HourglassDisabled,
	TaskAlt,
} from '@mui/icons-material';
import {
	Accordion,
	AccordionSummary,
	Badge,
	Chip,
	Divider,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import { useMemo } from 'react';
import { ISummaryData } from '../../../DTOs';
import { months } from '../../../utils/constants';
import SummaryCardDetails from './SummaryCardDetails';

interface ISummaryCardProps {
	data: ISummaryData;
}

const SummaryCard = ({ data }: ISummaryCardProps) => {
	const {
		period,
		paymentDate,
		toReceive,
		totalDelayed,
		totalDiverted,
		totalOntime,
	} = useMemo(() => {
		const month = new Date(`${data.period.from}T00:00:00`).getUTCMonth() + 1;

		const period = {
			from: new Date(`${data.period.from}T00:00:00`).getUTCDate(),
			to: new Date(`${data.period.to}T00:00:00`).getUTCDate(),
			month: months[month],
		};

		const paymentDate = new Date(
			`${data.period.payment}T00:00:00`
		).toLocaleDateString('pt-BR', {
			day: '2-digit',
			month: 'long',
		});

		const totalAmount = data.deliveries.reduce(
			(acc, { onTimeValue, delayedValue, divertedValue }) =>
				(acc +=
					Number(onTimeValue) + Number(delayedValue) + Number(divertedValue)),
			0
		);

		const totalAmountFormatted = new Intl.NumberFormat('pt-BR', {
			style: 'currency',
			currency: 'BRL',
		}).format(totalAmount);

		const totalOntime = data.deliveries.reduce(
			(acc, { onTimeQuantity }) => (acc += Number(onTimeQuantity)),
			0
		);

		const totalDelayed = data.deliveries.reduce(
			(acc, { delayedQuantity }) => (acc += Number(delayedQuantity)),
			0
		);

		const totalDiverted = data.deliveries.reduce(
			(acc, { divertedQuantity }) => (acc += Number(divertedQuantity)),
			0
		);

		return {
			period,
			paymentDate,
			toReceive: {
				totalAmount,
				totalAmountFormatted,
			},
			totalOntime,
			totalDelayed,
			totalDiverted,
		};
	}, [data]);

	return (
		<Accordion>
			<AccordionSummary expandIcon={<ExpandMore />}>
				<Stack
					width="100%"
					direction="column"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={2}
				>
					<Typography fontSize="1rem" fontWeight="600">
						Entregas de {period.from} até {period.to} de {period.month}
					</Typography>

					<Typography
						fontSize="0.9rem"
						display="flex"
						flexDirection="row"
						justifyContent="flex-start"
						alignItems="center"
						gap={1}
					>
						Pagamento em {paymentDate}
					</Typography>

					<Chip
						label={toReceive.totalAmountFormatted}
						color={toReceive.totalAmount > 0 ? 'success' : 'error'}
					/>

					<Divider light />

					<Stack
						width="100%"
						display="flex"
						flexDirection="row"
						justifyContent="space-evenly"
						alignItems="center"
						gap={1}
					>
						<Tooltip title="Entregas no prazo">
							<Badge max={9999} badgeContent={totalOntime} color="primary">
								<TaskAlt color={totalOntime > 0 ? 'success' : 'disabled'} />
							</Badge>
						</Tooltip>

						<Tooltip title="Entregas fora do prazo">
							<Badge max={9999} badgeContent={totalDelayed} color="primary">
								<HourglassDisabled
									color={totalDelayed > 0 ? 'warning' : 'disabled'}
								/>
							</Badge>
						</Tooltip>

						<Tooltip title="Entregas extraviadas">
							<Badge max={9999} badgeContent={totalDiverted} color="primary">
								<Error color={totalDiverted > 0 ? 'error' : 'disabled'} />
							</Badge>
						</Tooltip>
					</Stack>
				</Stack>
			</AccordionSummary>
			<SummaryCardDetails data={data.deliveries} />
		</Accordion>
	);
};

export default SummaryCard;
