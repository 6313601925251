import {
	Stack,
	TextField,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from '@mui/material';
import React, { useState } from 'react';

interface IOccurrenceData {
	id: string;
	occurrence: string;
}

interface IOccurrenceResponse {
	occurrence: string;
	description: string;
}

interface IOccurrenceProps {
	handleOccurrence: (data: IOccurrenceResponse) => void;
	occurrences: IOccurrenceData[];
}

const Occurrence = ({ handleOccurrence, occurrences }: IOccurrenceProps) => {
	const [occurrence, setOccurrence] = useState<IOccurrenceData | null>(null);
	const [description, setDescription] = useState('');

	return (
		<Stack
			width="100%"
			direction="column"
			justifyContent="flex-start"
			alignItems="stretch"
			spacing={2}
		>
			{!occurrence ? (
				<Stack
					width="100%"
					direction="column"
					justifyContent="flex-start"
					alignItems="stretch"
					spacing={2}
				>
					<Typography>Selecione o tipo para continuar</Typography>
					<FormControl fullWidth>
						<InputLabel id="demo-simple-select-label">Ocorrência</InputLabel>
						<Select
							name="occurrences"
							id="occurrences"
							label="Tipo de ocorrência"
							onChange={(e) =>
								setOccurrence(
									occurrences.filter((o) => o.id === e.target.value)[0]
								)
							}
						>
							{occurrences.map((o) => (
								<MenuItem value={o.id} key={o.id}>
									{o.occurrence}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Stack>
			) : (
				<Stack
					width="100%"
					direction="column"
					justifyContent="flex-start"
					alignItems="stretch"
					spacing={2}
				>
					<Typography>Ocorrência</Typography>
					<Typography fontWeight="600">{occurrence.occurrence}</Typography>
					<TextField
						placeholder="Descrição..."
						value={description}
						onChange={(e) => setDescription(e.target.value)}
					/>
					<Stack
						direction="row"
						width="100%"
						justifyContent="space-between"
						alignItems="center"
					>
						<Button
							onClick={() =>
								handleOccurrence({
									occurrence: occurrence.id,
									description,
								})
							}
							variant="contained"
						>
							Reportar
						</Button>
						<Button variant="outlined" onClick={() => setOccurrence(null)}>
							Cancelar
						</Button>
					</Stack>
				</Stack>
			)}
		</Stack>
	);
};

export default Occurrence;
