/* eslint-disable no-plusplus */
import { AxiosResponse } from 'axios';
import { IDeliveryActionsRequest } from '../DTOs';
import api from '../services/api';
import convertBase64ToFile from './convertBase64ToFile';
import convertURItoBlob from './convertURItoBlob';

interface ISendRequestProps {
	url?: string;
	packages?: IDeliveryActionsRequest[];
	destination?: string | null;
}

export const sendRequest = async ({
	url,
	packages,
	destination,
}: ISendRequestProps): Promise<AxiosResponse> => {
	if (!url) throw new Error('Invalid url');

	if (!packages) throw new Error('Invalid request data');

	const response = await api.post(url, { destination, packages });

	const { data } = response;

	if (data.errors && data.errors.length > 0) {
		throw new Error(data.errors[0].err);
	}

	return response;
};

export interface IDeliveryRequestProps {
	packages?: IDeliveryActionsRequest[];
}

export const deliverySendRequest = async ({
	packages,
}: IDeliveryRequestProps): Promise<AxiosResponse> => {
	if (!packages) throw new Error('Invalid request data');

	const data = new FormData();
	data.append('packages', JSON.stringify(packages));
	if (packages[0].data.receiver?.signature) {
		const signature = convertURItoBlob(
			packages[0].data.receiver?.signature,
			`${packages[0].term}-signature.png`
		);
		data.append('signature', signature as File);
	}
	if (packages[0].data.receiver?.attachments) {
		packages[0].data.receiver?.attachments.forEach((attachment) => {
			const file = convertBase64ToFile(attachment);

			data.append(`attachments`, file);
		});
	}

	const response = await api.post('/operations/delivered', data, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

	const { data: responseData } = response;

	if (responseData.errors && responseData.errors.length > 0) {
		throw new Error(responseData.errors[0].err);
	}

	return response;
};
