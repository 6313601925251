import { Alert, AlertTitle, Badge, Button, Stack } from '@mui/material';
import { MdInventory } from 'react-icons/md';
import { IPackage } from '../../../DTOs';

interface IPackageDisplayButtonProps {
	collecting: IPackage[];
	handleOpen: () => void;
}

const PackageDisplayButton = ({
	collecting,
	handleOpen,
}: IPackageDisplayButtonProps) => (
	<Stack
		width="100%"
		direction="column"
		justifyContent="flex-start"
		alignItems="center"
		spacing={2}
	>
		{collecting.length === 0 ? (
			<Alert
				sx={{
					width: '100%',
				}}
				severity="success"
				variant="outlined"
				icon={
					<Badge
						color="primary"
						badgeContent={collecting.length}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
					>
						<MdInventory color="action" />
					</Badge>
				}
			>
				Nenhuma atualização
			</Alert>
		) : (
			<Alert
				sx={{
					width: '100%',
				}}
				severity="info"
				variant="outlined"
				icon={
					<Badge
						color="primary"
						badgeContent={collecting.length}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
					>
						<MdInventory color="action" />
					</Badge>
				}
			>
				<AlertTitle>Você tem pacotes para coletar</AlertTitle>

				<Button
					sx={{
						width: '100%',
					}}
					variant="text"
					size="large"
					fullWidth
					onClick={handleOpen}
				>
					Ver pacotes
				</Button>
			</Alert>
		)}
	</Stack>
);

export default PackageDisplayButton;
