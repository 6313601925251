import { ExpandMore, Inventory, RvHookup } from '@mui/icons-material';

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Badge,
	Box,
	Stack,
	Typography,
} from '@mui/material';
import { ITransfer } from '../../DTOs/ITransfer';
import { ContractChip } from '../../components/ContractChip';

const TransferCard = ({ item: { dock, packages } }: { item: ITransfer }) => (
	<Accordion
		sx={{
			width: '100%',
		}}
	>
		<AccordionSummary expandIcon={<ExpandMore />}>
			<Stack
				width="100%"
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				spacing={1}
			>
				<Box
					sx={{
						position: 'relative',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						justifyContent: 'space-between',
						width: '100%',
						gap: '0.5rem',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '100%',
							gap: '1rem',
						}}
					>
						<RvHookup />
						<Typography
							fontSize="1rem"
							fontWeight="600"
							sx={{
								width: '100%',
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							{dock.name.substring(0, 25)}
						</Typography>
						<Badge
							sx={{ position: 'absolute', right: '0.2rem', top: '1rem' }}
							color="primary"
							badgeContent={packages.length}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
						>
							<Inventory color="primary" />
						</Badge>
					</Box>
					<Typography sx={{ mb: 1.2 }} fontSize="0.8rem">
						{dock.address} , {dock.number}
					</Typography>
				</Box>
			</Stack>
		</AccordionSummary>
		<AccordionDetails>
			<Stack
				width="100%"
				direction="column"
				justifyContent="flex-start"
				alignItems="center"
				spacing={2}
			>
				{packages.map((pkg) => (
					<Box
						key={pkg.code}
						sx={{
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Typography
							width="100%"
							key={pkg.code}
							fontSize="1.2rem"
							fontWeight="600"
							color="text.secondary"
							textAlign="center"
							sx={{
								lineBreak: 'anywhere',
								wordBreak: 'break-all',
								lineHeight: '1.5rem',
							}}
						>
							{pkg.code}
						</Typography>
						<ContractChip
							isReverse={pkg.isReverse}
							isShipFromStore={pkg.isShipFromStore}
						/>
					</Box>
				))}
			</Stack>
		</AccordionDetails>
	</Accordion>
);

export default TransferCard;
