import { Chip } from '@mui/material';

interface IContractChipProps {
	isReverse: boolean;
	isShipFromStore: boolean;
}

const ContractChip = ({ isReverse, isShipFromStore }: IContractChipProps) => {
	if (isReverse === true) {
		return <Chip label="reversa" size="small" color="warning" />;
	}

	if (isReverse === false && isShipFromStore === true) {
		return <Chip label="ship" size="small" color="info" />;
	}

	return <Chip label="pacote" size="small" />;
};

export { ContractChip };
