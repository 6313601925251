import { Launch, LocationOn, Tag, Person, Home } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { ReactNode, useMemo, useState } from 'react';
import { IPackage } from '../../../DTOs';

interface IMarker {
	delivery: IPackage;
	lat: number;
	lng: number;
	onNavigate: (item: IPackage) => void;
}

const MarkerIcon = (props: any) => <LocationOn {...props} color="error" />;

const Item = ({ children }: { children: ReactNode }) => (
	<Typography
		fontSize="0.8rem"
		fontWeight="600"
		display="flex"
		flexDirection="row"
		justifyContent="center"
		alignItems="center"
	>
		{children}
	</Typography>
);

const Marker = ({ delivery, lat, lng, onNavigate }: IMarker) => {
	const [open, setOpen] = useState(false);

	const deliveryData = useMemo(() => {
		const pkgLocation = `${delivery.neighborhood}-${delivery.city}`;

		const recipient = String(delivery.recipient).toUpperCase();

		return { ...delivery, pkgLocation, recipient };
	}, [delivery]);

	return (
		<>
			{open && (
				<Stack
					width="250px"
					height="fit-content"
					direction="column"
					justifyContent="flex-start"
					alignItems="flex-start"
					bgcolor="#fafafa"
					position="absolute"
					borderRadius="16px"
					border="1px solid rgba(0,0,0,0.1)"
					padding="8px"
					sx={{
						transform: 'translate(-120px,25px)',
					}}
				>
					<Box
						width="100%"
						height="5px"
						display="flex"
						flexDirection="row"
						justifyContent="flex-end"
						alignItems="center"
						position="absolute"
						top="15px"
						right="15px"
					>
						<Launch onClick={() => onNavigate(deliveryData)} />
					</Box>
					<Item>
						<Tag color="action" /> {deliveryData.alphaCode}
					</Item>

					<Item>
						<Home color="action" />
						{deliveryData.address}, {deliveryData.addressNumber}
					</Item>

					<Item>
						<Person color="action" />
						{deliveryData.recipient}
					</Item>
				</Stack>
			)}
			<MarkerIcon lat={lat} lng={lng} onClick={() => setOpen(!open)} />
		</>
	);
};

export default Marker;
