import apm from '../services/elastic-apm';

export const logError = (error: Error | string | object | any) => {
	if (error instanceof Error) {
		apm.captureError(error);
	} else if (typeof error === 'string') {
		apm.captureError(error);
	} else {
		apm.captureError(JSON.stringify(error));
	}
};
