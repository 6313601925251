import { Box, Stack, Typography } from '@mui/material';
import { IManifest } from '../../../DTOs/IManifests';
import ManifestActions from '../ManifestActions';

interface ICardDetailsProps {
	manifest: IManifest;
}

const CardDetails = ({
	manifest: { origin, destination, packages, id, driverSignedAt, document },
}: ICardDetailsProps) => (
	<Stack sx={{ gap: '1rem' }}>
		<Box sx={{ display: 'flex', gap: '2rem' }}>
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<Typography variant="subtitle2">Origem</Typography>
				<Typography variant="body2">{origin.name}</Typography>
			</Box>
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<Typography variant="subtitle2">Destino</Typography>
				<Typography variant="body2">
					{destination ? destination.name : 'N/D'}
				</Typography>
			</Box>
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<Typography variant="subtitle2">Pacotes</Typography>
				<Typography variant="body2">{packages.length}</Typography>
			</Box>
		</Box>
		<ManifestActions
			manifestId={id}
			isSigned={!!driverSignedAt}
			manifestDocument={document}
		/>
	</Stack>
);

export default CardDetails;
