import { createTheme, ThemeOptions } from '@mui/material';
import { ptBR } from '@mui/material/locale';

export const themeOptions: ThemeOptions = {
	palette: {
		mode: 'light',
		primary: {
			main: '#1253da',
		},
		secondary: {
			main: '#febc2d',
		},
		error: {
			main: '#DC143C',
		},
	},
	typography: {
		fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
	},
};

export const theme = createTheme(themeOptions, ptBR);
