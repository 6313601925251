import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';

const NavigationBar = () => {
	const { isEmployee, canCollect } = useAuth();
	const [value, setValue] = useState(0);

	const navigate = useNavigate();
	const location = useLocation();

	const routes = useMemo(
		() =>
			[
				{
					index: 0,
					enabled: canCollect,
					name: 'Coletas',
					path: '/coletas',
					icon: <AirlineStopsIcon />,
				},
				{
					index: 1,
					enabled: true,
					name: 'Entregas',
					path: '/entregas',
					icon: <LocalShippingIcon />,
				},
				{
					index: 2,
					enabled: isEmployee,
					name: 'Carteira',
					path: '/carteira',
					icon: <AccountBalanceWalletIcon />,
				},
			].filter((item) => item.enabled),
		[canCollect, isEmployee]
	);

	useEffect(() => {
		const currentLocation = routes.find((l) =>
			location.pathname.includes(l.path)
		);

		if (currentLocation) {
			setValue(currentLocation.index);
		}

		if (!currentLocation) {
			setValue(-1);
		}
	}, [location.pathname, routes]);

	return (
		<Box width="100%">
			<BottomNavigation
				showLabels
				value={value}
				onChange={(_, newValue) => {
					const newRoute = routes[newValue];
					navigate(newRoute.path);
					setValue(newValue);
				}}
			>
				{routes.map((r) => (
					<BottomNavigationAction key={r.index} label={r.name} icon={r.icon} />
				))}
			</BottomNavigation>
		</Box>
	);
};

export { NavigationBar };
