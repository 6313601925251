// eslint-disable-next-line import/no-extraneous-dependencies
import { faker } from '@faker-js/faker';
import { Factory } from 'miragejs';

const walletFactory = Factory.extend({
	deliveries() {
		const deliveries = Array(faker.datatype.number({ min: 5, max: 20 }))
			.fill(null)
			.map(() => ({
				driverId: faker.datatype.number({ max: 13 }),
				driver: faker.internet.userName(),
				dock: faker.address.city(),
				cycle: faker.date.recent(),
				paymentDate: faker.date.future(),
				periodFrom: faker.date.past(),
				periodTo: faker.date.past(),
				onTimeQuantity: faker.datatype.number({ min: 5, max: 400 }),
				onTimeValue: faker.datatype.number({
					min: 900,
					max: 8000,
					precision: 2,
				}),
				delayedQuantity: faker.datatype.number({ min: 5, max: 400 }),
				delayedValue: faker.datatype.number({
					min: 0,
					max: 1200,
					precision: 2,
				}),
				divertedQuantity: faker.datatype.number({ min: 0, max: 30 }),
				divertedValue: faker.datatype.number({
					min: 0,
					max: 1200,
					precision: 2,
				}),
			}));

		return deliveries;
	},
	pickups() {
		return [];
	},
});

export default walletFactory;
