import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactNode } from 'react';

interface IActionProps {
	title: string;
	component: ReactNode;
}

const Action = ({ title, component }: IActionProps) => (
	<Accordion
		sx={{
			width: '100%',
		}}
	>
		<AccordionSummary expandIcon={<ExpandMoreIcon />}>
			<Typography>{title}</Typography>
		</AccordionSummary>
		<AccordionDetails>{component}</AccordionDetails>
	</Accordion>
);

export default Action;
