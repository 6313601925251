import {
	Button,
	Card,
	CardActions,
	CardContent,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { IPackage } from '../../../DTOs';
import ReverseBadge from '../../../components/ReverseBadge';
import OccurrenceDialog from './OccurrenceDialog';

interface IPackageCardProps {
	package: IPackage;
	selected: boolean;
	toggleSelection: (code: string) => void;
}

const PackageCard = ({
	package: { location, zipcode, alphaCode, recipient, isReverse },
	selected,
	toggleSelection,
}: IPackageCardProps) => {
	const [occurrencePackage, setOccurrencePackage] = useState<string | null>(
		null
	);

	return (
		<>
			<OccurrenceDialog
				occurrencePackage={occurrencePackage}
				handleClose={() => setOccurrencePackage(null)}
			/>
			<Card sx={{ width: '100%' }}>
				<CardContent>
					<Typography variant="h5" component="div">
						<ReverseBadge isReverse={isReverse}>{alphaCode}</ReverseBadge>
					</Typography>
					<Typography sx={{ mb: 1.5 }} color="text.secondary">
						{recipient}
					</Typography>

					<Typography sx={{ mb: 1.5 }} color="text.secondary">
						{location}
					</Typography>
					<Typography variant="body2">{zipcode}</Typography>
				</CardContent>
				<CardActions>
					{isReverse && (
						<Button
							fullWidth
							size="large"
							variant="contained"
							color="error"
							onClick={() => setOccurrencePackage(alphaCode)}
						>
							Ocorrência
						</Button>
					)}

					<Button
						fullWidth
						size="large"
						variant={selected ? 'contained' : 'outlined'}
						onClick={() => toggleSelection(alphaCode)}
					>
						{selected ? 'Remover' : 'Selecionar'}
					</Button>
				</CardActions>
			</Card>
		</>
	);
};

export default PackageCard;
