import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Box from '@mui/material/Box';
import AssignmentIcon from '@mui/icons-material/Assignment';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from '../../hooks/auth';

const NavigationMenu = () => {
	const { isEmployee, canCollect } = useAuth();
	const [state, setState] = useState(false);
	const navigate = useNavigate();

	const routes = useMemo(
		() =>
			[
				{
					index: 0,
					enabled: canCollect,
					name: 'Coletas',
					path: '/coletas',
					icon: <AirlineStopsIcon />,
				},
				{
					index: 1,
					enabled: true,
					name: 'Entregas',
					path: '/entregas',
					icon: <LocalShippingIcon />,
				},
				{
					index: 2,
					enabled: isEmployee,
					name: 'Carteira',
					path: '/carteira',
					icon: <AccountBalanceWalletIcon />,
				},
				{
					index: 3,
					enabled: true,
					name: 'Transferencias',
					path: '/transferencias',
					icon: <RvHookupIcon />,
				},
				{
					index: 4,
					enabled: true,
					name: 'Romaneios',
					path: '/romaneios',
					icon: <AssignmentIcon />,
				},
			].filter((item) => item.enabled),
		[canCollect, isEmployee]
	);

	const toggleDrawer =
		(open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
			if (
				event.type === 'keydown' &&
				((event as React.KeyboardEvent).key === 'Tab' ||
					(event as React.KeyboardEvent).key === 'Shift')
			) {
				return;
			}

			setState(open);
		};

	return (
		<>
			<IconButton onClick={toggleDrawer(true)} aria-label="Menu" size="large">
				<MenuIcon fontSize="inherit" sx={{ color: '#FFFF' }} />
			</IconButton>

			<Drawer anchor="left" open={state} onClose={toggleDrawer(false)}>
				<Box
					role="presentation"
					onClick={toggleDrawer(false)}
					onKeyDown={toggleDrawer(false)}
				>
					<List>
						{routes.map((route) => (
							<ListItem key={route.index} disablePadding>
								<ListItemButton
									onClick={() => {
										navigate(route.path);
									}}
								>
									<ListItemIcon>{route.icon}</ListItemIcon>
									<ListItemText primary={route.name} />
								</ListItemButton>
							</ListItem>
						))}
					</List>
				</Box>
			</Drawer>
		</>
	);
};

export { NavigationMenu };
