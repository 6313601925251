import {
	Alert,
	Badge,
	Box,
	Button,
	CircularProgress,
	Modal,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from '../../../components/Layout';
import { IDriverPackages, IPackage } from '../../../DTOs';
import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';
import { logError } from '../../../utils/error';
import { handleSearchPackage } from '../../../utils/filtering';
import DeliverActions from './DeliverActions';
import PackageCard from './PackageCard';

const NeighborhoodDeliveries = () => {
	const [neighborhood, setNeighborhood] = useState('');
	const [details, setDetails] = useState<IPackage | null>(null);
	const [search, setSearch] = useState('');
	const { user } = useAuth();

	const location = useLocation();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const { isLoading, data } = useQuery<IDriverPackages>(
		[`/drivers/${user.id}/deliveries`],
		async () => {
			const { data } = await api.get<IDriverPackages>(
				`/drivers/${user.id}/deliveries`
			);

			return data;
		},
		{
			onError: (error) => {
				logError(error);
				enqueueSnackbar('Não foi possível receber os pedidos de entrega', {
					variant: 'error',
				});
			},
			networkMode: 'offlineFirst',
		}
	);

	useEffect(() => {
		if (!location.state) {
			navigate('/entregas');
		}

		const { neighborhood, code } = location.state;

		if (!neighborhood) {
			navigate('/entregas');
		}

		setNeighborhood(neighborhood);

		if (code && data && !isLoading) {
			const redirectedPackage = data.packages.find((p) => p.alphaCode === code);

			if (redirectedPackage) {
				setDetails(redirectedPackage);
			}
		}
	}, [data, isLoading, location, navigate]);

	const neighborhoodPackages = useMemo(() => {
		if (!data) return [];

		return data.packages
			.filter((item) => item.neighborhood === neighborhood)
			.filter((item) =>
				search.length > 0 ? handleSearchPackage(item, search) : true
			);
	}, [data, neighborhood, search]);

	if (isLoading) {
		return (
			<Layout>
				<Box
					sx={{
						display: 'flex',
						width: '100%',
						height: '100%',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CircularProgress size="4rem" />
				</Box>
			</Layout>
		);
	}

	return (
		<Layout>
			{details && (
				<Modal
					open={!!details}
					onClose={() => setDetails(null)}
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<DeliverActions data={details} handleClose={() => setDetails(null)} />
				</Modal>
			)}
			<Stack
				width="100%"
				maxWidth="400px"
				direction="column"
				justifyContent="flex-start"
				alignItems="center"
				padding={2}
				spacing={2}
			>
				<Button
					fullWidth
					variant="contained"
					onClick={() => navigate('/entregas')}
				>
					Voltar
				</Button>
				<Stack
					direction="row"
					width="100%"
					justifyContent="center"
					alignItems="center"
					spacing={2}
				>
					<Badge
						color="primary"
						badgeContent={neighborhoodPackages.length}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
					>
						<Typography fontSize="1.2rem" fontWeight="600">
							{neighborhood}
						</Typography>
					</Badge>
				</Stack>

				<TextField
					id="search"
					label="Buscar"
					variant="outlined"
					sx={{ width: '100%' }}
					value={search}
					onChange={(e) => setSearch(e.target.value)}
				/>

				{neighborhoodPackages.length === 0 ? (
					<Alert sx={{ width: '100%' }} severity="success">
						Nenhum pacote disponível no momento
					</Alert>
				) : (
					neighborhoodPackages.map((item) => (
						<PackageCard
							data={item}
							key={item.alphaCode}
							showDetails={setDetails}
						/>
					))
				)}
			</Stack>
		</Layout>
	);
};

export default NeighborhoodDeliveries;
