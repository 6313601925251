import { decrypt, encrypt } from '../crypto';

export class Local {
	public storage = localStorage;

	public setEncrypted(key: string, value: string) {
		const encrypted = encrypt(value);

		if (!encrypted) return encrypted;

		this.storage.setItem(key, encrypted);

		return encrypted;
	}

	public removeEncrypted(key: string) {
		this.storage.removeItem(key);
	}

	public getEncrypted(key: string) {
		const encrypted = this.storage.getItem(key);

		if (!encrypted) return encrypted;

		const decryptedData = decrypt(encrypted);

		if (!decryptedData) {
			this.removeEncrypted(key);
			return null;
		}

		return decryptedData;
	}

	public clear() {
		this.storage.clear();
	}
}
