import {
	CircularProgress,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Stack,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { IDocks } from '../../DTOs';
import api from '../../services/api';
import { theme } from '../../styles/theme';

interface IDockSelectorProps {
	onChange: (dock: string) => void;
}

const DockSelector = ({ onChange }: IDockSelectorProps) => {
	const { isLoading, data: docks } = useQuery(
		['docks'],
		async () => {
			const { data } = await api.get<IDocks[]>('/docks');

			return data;
		},
		{
			staleTime: 1000 * 60 * 5,
		}
	);

	if (isLoading)
		return (
			<Stack>
				<CircularProgress size="2rem" color="inherit" />
			</Stack>
		);

	return (
		<FormControl fullWidth variant="outlined" required>
			<InputLabel id="dock-select">Destino</InputLabel>
			<Select
				sx={{
					boder: `5px solid ${theme.palette.primary.main}`,
				}}
				defaultValue=""
				labelId="dock-select"
				id="dock-select"
				label="Termo de busca"
				onChange={(e) => onChange(e.target.value)}
			>
				{!!docks &&
					docks.map((d) => (
						<MenuItem value={d.id} key={d.id}>
							{d.dock}
						</MenuItem>
					))}
			</Select>
		</FormControl>
	);
};

export default DockSelector;
