/* eslint-disable no-plusplus */
import { IAttachments } from '../DTOs';

const convertBase64ToFile = (attachment: IAttachments) => {
	const byteString = atob(attachment.base64);
	const arrayBuffer = new ArrayBuffer(byteString.length);
	const int8Array = new Uint8Array(arrayBuffer);
	for (let i = 0; i < byteString.length; i++) {
		int8Array[i] = byteString.charCodeAt(i);
	}
	const blob = new Blob([int8Array], { type: attachment.type });

	const file = new File([blob], attachment.alt, { type: attachment.type });

	return file;
};

export default convertBase64ToFile;
