import { Box, Button, Modal, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
	IAttachments,
	IDeliveryActions,
	IDeliveryData,
} from '../../../../../../DTOs';
import AttachmentsBadge from './AttachmentsBadge';
import CameraCapture from './CameraCapture';
import Signature from './Signature';

interface IDeliverProps {
	handleDeliver: (data: IDeliveryData) => void;
	code: string;
}

const Deliver: React.FC<IDeliverProps> = ({ handleDeliver, code }) => {
	const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
	const [isCameraModalOpen, setIsCameraModalOpen] = useState(false);

	const { register, watch, setValue } = useFormContext<IDeliveryActions>();

	const { receiver } = watch();

	const handleDelivery = () => {
		if (receiver) {
			const deliveryData: IDeliveryData = {
				name: receiver.name || '',
				document: receiver.document || '',
				signature: receiver.signature as string,
				attachments: receiver.attachments as IAttachments[],
			};
			handleDeliver(deliveryData);
		}
	};

	return (
		<Stack gap={2}>
			<TextField placeholder="Nome" required {...register('receiver.name')} />
			<TextField
				placeholder="Documento"
				required
				{...register('receiver.document')}
			/>

			<Modal
				open={isSignatureModalOpen}
				onClose={() => setIsSignatureModalOpen(false)}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Box>
					<Signature showSignModal={setIsSignatureModalOpen} />
				</Box>
			</Modal>

			<Modal
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
				}}
				open={isCameraModalOpen}
				onClose={() => setIsCameraModalOpen(false)}
			>
				<Box>
					<CameraCapture showCameraModal={setIsCameraModalOpen} code={code} />
				</Box>
			</Modal>

			<Button variant="contained" onClick={() => setIsSignatureModalOpen(true)}>
				Assinar
			</Button>
			<Button
				disabled={receiver && receiver.attachments?.length === 2}
				variant="outlined"
				onClick={() => setIsCameraModalOpen(true)}
			>
				Capturar Foto
			</Button>
			<Box
				sx={{
					display: 'flex',
					gap: 2,
					flexWrap: 'wrap',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				{receiver &&
					receiver.attachments &&
					receiver.attachments.map((attachment, index) => (
						<AttachmentsBadge
							key={attachment.base64}
							image={attachment}
							onDelete={() => {
								setValue(
									'receiver.attachments',
									receiver.attachments &&
										receiver.attachments.filter((_, i) => i !== index)
								);
							}}
						/>
					))}
			</Box>
			<Button
				variant="contained"
				onClick={handleDelivery}
				disabled={
					(receiver && receiver.name.length < 3) ||
					(receiver && receiver.document.length < 3)
				}
			>
				Entregar
			</Button>
		</Stack>
	);
};

export default Deliver;
