import { IPackage } from '../DTOs';

export const searchPackageByAddress = (pkg: IPackage, searchTerm: string) =>
	String(pkg.address).toUpperCase().includes(String(searchTerm).toUpperCase());

export const searchPackageByNeighborhood = (
	pkg: IPackage,
	searchTerm: string
) =>
	String(pkg.neighborhood)
		.toUpperCase()
		.includes(String(searchTerm).toUpperCase());

export const searchPackageByRecipient = (pkg: IPackage, searchTerm: string) =>
	String(pkg.recipient)
		.toUpperCase()
		.includes(String(searchTerm).toUpperCase());

export const searchPackageByCode = (pkg: IPackage, searchTerm: string) =>
	String(pkg.alphaCode)
		.toUpperCase()
		.includes(String(searchTerm).toUpperCase());

export const searchPackageAddressNumber = (pkg: IPackage, searchTerm: string) =>
	String(pkg.addressNumber)
		.toUpperCase()
		.includes(String(searchTerm).toUpperCase());

export const handleSearchPackage = (pkg: IPackage, searchTerm: string) =>
	searchPackageByAddress(pkg, searchTerm) ||
	searchPackageByNeighborhood(pkg, searchTerm) ||
	searchPackageByRecipient(pkg, searchTerm) ||
	searchPackageAddressNumber(pkg, searchTerm) ||
	searchPackageByCode(pkg, searchTerm);
