import { Box, Stack } from '@mui/material';
import { ReactNode } from 'react';
import { Header } from './Header';
import { NavigationBar } from './NavigationBar';

interface ILayoutProps {
	children: ReactNode;
}

const Layout = ({ children }: ILayoutProps) => (
	<Stack
		direction="column"
		justifyContent="space-between"
		alignItems="stretch"
		sx={{
			width: '100vw',
			height: '100vh',
			m: 0,
			p: 0,
		}}
	>
		<Header />
		<Box
			sx={{
				width: '100%',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-start',
				alignItems: 'center',
				overflowY: 'scroll',
				m: 0,
				p: 0,
			}}
		>
			{children}
		</Box>
		<NavigationBar />
	</Stack>
);

export default Layout;
