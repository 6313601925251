import { Button } from '@mui/material';
import { CronJob } from 'cron';
import { SnackbarKey, useSnackbar } from 'notistack';
import {
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OfflineWarning from '../components/OfflineWarning';
import {
	IRequestQueueItem,
	dequeueFailedRequest,
	dequeuePendingRequest,
	getFailedRequests,
	getPendingRequests,
	retryFailedRequest,
} from '../store/requestQueueSlice';
import { deliverySendRequest, sendRequest } from '../utils/driverActions';
import { logError } from '../utils/error';

const RequestQueue = createContext({});

interface IRequestQueueProps {
	children: ReactNode;
}

const RequestQueueProvider = ({ children }: IRequestQueueProps) => {
	const [isOnline, setIsOnline] = useState(navigator.onLine);
	const [offlineAcknoledment, setOffileAcknoledgement] = useState(false);
	const [offlineWarningKey, setOfflineWarningKey] =
		useState<SnackbarKey | null>(null);

	const pendingRequests = useSelector(getPendingRequests());
	const failedRequests = useSelector(getFailedRequests());
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const dispatch = useDispatch();

	const handleNetworkChange = useCallback(() => {
		if (navigator.onLine) {
			setIsOnline(true);
			enqueueSnackbar('Você está online novamente!', {
				variant: 'success',
				preventDuplicate: true,
			});
			if (offlineWarningKey) closeSnackbar(offlineWarningKey);
		} else {
			setIsOnline(false);
			const key = enqueueSnackbar(
				'Você está offline, mas não se preocupe, suas ações serão salvas e enviadas quando estiver online novamente',
				{
					variant: 'warning',
					persist: !offlineAcknoledment,
					autoHideDuration: 1000,
					preventDuplicate: true,
					action: (
						<Button
							variant="outlined"
							fullWidth
							sx={{
								color: '#fafafa',
								borderColor: '#fafafa',
							}}
							onClick={() => {
								setOffileAcknoledgement(true);
								closeSnackbar(key);
							}}
						>
							Ok, entendi
						</Button>
					),
				}
			);
			setOfflineWarningKey(key);
		}
	}, [closeSnackbar, enqueueSnackbar, offlineAcknoledment, offlineWarningKey]);

	const handleSendRequest = useCallback(
		async (request: IRequestQueueItem) => {
			try {
				if (request.url === '/operations/delivered') {
					await deliverySendRequest({
						packages: request.body,
					});
				} else {
					await sendRequest({
						url: request.url,
						packages: request.body,
					});
				}

				dispatch(dequeuePendingRequest({ id: request.id }));
			} catch (error) {
				logError(error);
				enqueueSnackbar(
					'Um ou mais pacotes que você interagiu enquanto offline apresentaram um erro no seu processamento, entre em contato com o suporte para mais informações',
					{
						title: 'Erro ao processar pacotes',
						variant: 'error',
						preventDuplicate: true,
					}
				);
			}
		},
		[dispatch, enqueueSnackbar]
	);

	useEffect(() => {
		window.addEventListener('load', handleNetworkChange);
		window.addEventListener('online', handleNetworkChange);
		window.addEventListener('offline', handleNetworkChange);

		return () => {
			window.removeEventListener('load', handleNetworkChange);
			window.removeEventListener('online', handleNetworkChange);
			window.removeEventListener('offline', handleNetworkChange);
		};
	}, [handleNetworkChange]);

	useEffect(() => {
		if (pendingRequests.length > 0 && isOnline) {
			pendingRequests.forEach((request) => handleSendRequest(request));
		}
	}, [handleSendRequest, isOnline, pendingRequests]);

	useEffect(() => {
		const resendFailedRequestsJob = new CronJob(
			'*/20 * * * * *',
			async () => {
				if (failedRequests.length > 0) {
					const [request] = failedRequests;

					try {
						await handleSendRequest(request);

						dispatch(dequeueFailedRequest({ id: request.id }));
					} catch (error) {
						logError(error);
						dispatch(retryFailedRequest(request));
					}
				}
			},
			null,
			true,
			'America/Sao_Paulo'
		);

		if (failedRequests.length === 0 && isOnline) {
			resendFailedRequestsJob.start();
		} else {
			resendFailedRequestsJob.stop();
		}

		return () => {
			resendFailedRequestsJob.stop();
		};
	}, [dispatch, failedRequests, handleSendRequest, isOnline]);

	const contextValue = useMemo(() => ({ isOnline }), [isOnline]);

	return (
		<RequestQueue.Provider value={contextValue}>
			{!isOnline && <OfflineWarning />}
			{children}
		</RequestQueue.Provider>
	);
};

function useRequestQueue() {
	const context = useContext(RequestQueue);

	return context;
}

export { RequestQueueProvider, useRequestQueue };
