import { Local } from './localStorage';
import { Session } from './sessionStorage';

export const local = new Local();

export const session = new Session();

export const storage = {
	clearAll() {
		local.clear();
		session.clear();
	},
};
