import { GlobalStyles, ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SnackbarProvider } from 'notistack';
import { ReactNode } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ptBrLocale from 'date-fns/locale/pt-BR';
import { queryClient } from '../services/queryClient';
import store from '../store';
import { global } from '../styles/globalStyle';
import { theme } from '../styles/theme';
import { AuthProvider } from './auth';
import { RequestQueueProvider } from './requestQueue';
import '../services/elastic-apm';

interface IAppProviderProps {
	children: ReactNode;
}

const persistor = persistStore(store);

const AppProvider = ({ children }: IAppProviderProps) => (
	<ThemeProvider theme={theme}>
		<LocalizationProvider
			dateAdapter={AdapterDateFns}
			adapterLocale={ptBrLocale}
		>
			<GlobalStyles styles={global.styles} />
			<SnackbarProvider
				anchorOrigin={{
					horizontal: 'right',
					vertical: 'top',
				}}
			>
				<QueryClientProvider client={queryClient}>
					<ReactQueryDevtools />
					<AuthProvider>
						<ReduxProvider store={store}>
							<PersistGate loading={null} persistor={persistor}>
								<RequestQueueProvider>{children}</RequestQueueProvider>
							</PersistGate>
						</ReduxProvider>
					</AuthProvider>
				</QueryClientProvider>
			</SnackbarProvider>
		</LocalizationProvider>
	</ThemeProvider>
);

export default AppProvider;
