import AccountCircle from '@mui/icons-material/AccountCircle';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { MouseEvent, useMemo, useState } from 'react';
import { useAuth } from '../../hooks/auth';
import { NavigationMenu } from './NavigationMenu';

const Header = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleMenu = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const { user, signOut, handleUpdateModality } = useAuth();

	const username = useMemo(() => {
		const invalid = !user || (!!user && !user.name);

		if (invalid) return 'Usuário';

		return String(user.name);
	}, [user]);

	return (
		<AppBar
			position="sticky"
			sx={{
				top: 0,
			}}
		>
			<Toolbar>
				<NavigationMenu />

				<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
					{username}
				</Typography>
				<div>
					<IconButton
						size="large"
						aria-label="account of current user"
						aria-controls="menu-appbar"
						aria-haspopup="true"
						onClick={handleMenu}
						color="inherit"
					>
						<AccountCircle />
					</IconButton>
					<Menu
						id="menu-appbar"
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						<MenuItem onClick={() => handleUpdateModality(null)}>
							Modalidades
						</MenuItem>
						<MenuItem onClick={signOut}>Sair</MenuItem>
					</Menu>
				</div>
			</Toolbar>
		</AppBar>
	);
};

export { Header };
