import { useInfiniteQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { useInView } from 'react-intersection-observer';
import { startOfDay, endOfDay } from 'date-fns';
import Layout from '../../components/Layout';
import ManifestCard from './ManifestCard';
import api from '../../services/api';
import { IGetManifestResponse } from '../../DTOs/IManifests';
import { logError } from '../../utils/error';

const Manifests = () => {
	const { enqueueSnackbar } = useSnackbar();
	const [dateFrom, setDateFrom] = useState(startOfDay(new Date()));
	const [dateTo, setDateTo] = useState(endOfDay(new Date()));
	const [onlyUnsigned, setOnlyUnsigned] = useState(true);
	const { ref, inView } = useInView();

	const fetchManifests = async ({ pageParam = 1 }) => {
		const { data } = await api.get<IGetManifestResponse>(`/drivers/manifests`, {
			params: {
				dateFrom,
				dateTo,
				page: pageParam,
				onlyUnsigned,
			},
		});

		return data;
	};

	const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } =
		useInfiniteQuery(
			['/drivers/manifests', dateFrom, dateTo, onlyUnsigned],
			fetchManifests,
			{
				getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
				onError: (error) => {
					logError(error);
					enqueueSnackbar('Não foi possível consultar estes dados', {
						variant: 'error',
					});
				},
			}
		);

	useEffect(() => {
		if (inView) {
			fetchNextPage();
		}
	}, [fetchNextPage, inView]);

	if (isLoading) {
		return (
			<Layout>
				<Box
					sx={{
						display: 'flex',
						width: '100%',
						height: '100%',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CircularProgress size="4rem" />
				</Box>
			</Layout>
		);
	}

	return (
		<Layout>
			<Box display="flex" gap={2} flexDirection="column" m="1rem">
				<DatePicker
					value={dateFrom}
					onChange={(newValue) => {
						if (newValue) {
							setDateFrom(startOfDay(newValue));
						}
					}}
					renderInput={(params) => <TextField {...params} fullWidth />}
					loading={isLoading}
					disabled={isLoading}
					minDate={new Date('2023-01-01')}
					openTo="day"
					views={['day', 'month', 'year']}
					label="De"
				/>

				<DatePicker
					value={dateTo}
					onChange={(newValue) => {
						if (newValue) {
							setDateTo(endOfDay(newValue));
						}
					}}
					renderInput={(params) => <TextField {...params} fullWidth />}
					loading={isLoading}
					disabled={isLoading}
					minDate={new Date('2023-01-01')}
					openTo="day"
					views={['day', 'month', 'year']}
					label="Para"
				/>
				<FormControlLabel
					sx={{ m: '0' }}
					control={
						<Checkbox
							checked={onlyUnsigned}
							value={onlyUnsigned}
							onChange={() => setOnlyUnsigned(!onlyUnsigned)}
						/>
					}
					label="Somente não assinados"
				/>
			</Box>

			<Stack
				sx={{
					width: '100%',
					justifyContent: 'center',
					alignItems: 'center',
					padding: '0.5rem',
					gap: 2,
				}}
			>
				{data?.pages.map((group) => (
					<React.Fragment key={group.currentPage}>
						{group.docs.map((manifest) => (
							<ManifestCard data={manifest} key={manifest.id} />
						))}
					</React.Fragment>
				))}
				<Button
					ref={ref}
					sx={{ display: data?.pages[0].totalDocs === 0 ? 'none' : 'block' }}
					disabled={!hasNextPage || isFetchingNextPage}
					onClick={() => fetchNextPage()}
				>
					{isFetchingNextPage ?? <CircularProgress size="4rem" />}
				</Button>
			</Stack>
		</Layout>
	);
};
export default Manifests;
