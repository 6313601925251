import { Alert, Button, Modal, Stack, TextField } from '@mui/material';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ScrollContainer from 'react-indiana-drag-scroll';
import { IPackage } from '../../DTOs';
import { handleSearchPackage } from '../../utils/filtering';
import PackageCard from './NeighborhoodDeliveries/PackageCard';

interface ISearchbarProps {
	packages: IPackage[];
}

const Searchbar = ({ packages }: ISearchbarProps) => {
	const [isActive, setActive] = useState(false);
	const [search, setSearch] = useState('');

	const navigate = useNavigate();

	const packageSearchResults = useMemo(
		() => packages.filter((item) => handleSearchPackage(item, search)),
		[packages, search]
	);

	const handleNavigate = (item: IPackage) => {
		setActive(false);
		navigate(`/entregas/bairro`, {
			state: {
				neighborhood: item.neighborhood,
				code: item.alphaCode,
			},
		});
	};

	return (
		<>
			<Button variant="outlined" fullWidth onClick={() => setActive(true)}>
				Buscar pacotes
			</Button>

			<Modal
				open={isActive}
				onClose={() => setActive(false)}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					alignItems: 'center',
					padding: 4,
					borderRadius: 4,
				}}
			>
				<Stack
					width="100%"
					maxWidth="400px"
					direction="column"
					justifyContent="flex-start"
					alignItems="center"
					spacing={2}
					bgcolor="#fafafa"
					display="flex"
					flexDirection="column"
					borderRadius={4}
					overflow="auto"
				>
					<ScrollContainer vertical hideScrollbars ignoreElements="input">
						<Stack padding={2} spacing={2}>
							<TextField
								fullWidth
								label="Buscar pacote"
								value={search}
								onChange={(event) => setSearch(event.target.value)}
							/>
							{packageSearchResults.length === 0 ? (
								<Alert severity="info">
									Nenhum pacote encontrado para esta busca
								</Alert>
							) : (
								packageSearchResults.map((item) => (
									<PackageCard
										data={item}
										key={item.alphaCode}
										showDetails={() => handleNavigate(item)}
									/>
								))
							)}
						</Stack>
					</ScrollContainer>
				</Stack>
			</Modal>
		</>
	);
};

export default Searchbar;
