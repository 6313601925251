import {
	Error,
	ExpandLess,
	ExpandMore,
	HourglassDisabled,
	TaskAlt,
} from '@mui/icons-material';
import {
	Avatar,
	Badge,
	Chip,
	Collapse,
	Divider,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	ListSubheader,
	Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { ISummaryItemData } from '../../../../DTOs';

const SummaryItem = ({ summary }: { summary: ISummaryItemData }) => {
	const [open, setOpen] = useState(false);

	const { delayed, diverted, ontime, total } = useMemo(() => {
		const totalValue = summary.deliveries.reduce(
			(acc, { onTimeValue, delayedValue, divertedValue }) =>
				(acc +=
					Number(onTimeValue) + Number(delayedValue) + Number(divertedValue)),
			0
		);

		const total = {
			value: totalValue,
			formatted: new Intl.NumberFormat('pt-BR', {
				style: 'currency',
				currency: 'BRL',
			}).format(totalValue),
			quantity: summary.deliveries.length,
		};

		const ontimeValue = summary.deliveries.reduce(
			(acc, { onTimeValue }) => (acc += Number(onTimeValue)),
			0
		);

		const ontime = {
			value: ontimeValue,
			formatted: new Intl.NumberFormat('pt-BR', {
				style: 'currency',
				currency: 'BRL',
			}).format(ontimeValue),
			quantity: summary.deliveries.reduce(
				(acc, { onTimeQuantity }) => (acc += Number(onTimeQuantity)),
				0
			),
		};

		const delayedValue = summary.deliveries.reduce(
			(acc, { delayedValue }) => (acc += Number(delayedValue)),
			0
		);

		const delayed = {
			value: delayedValue,
			formatted: new Intl.NumberFormat('pt-BR', {
				style: 'currency',
				currency: 'BRL',
			}).format(delayedValue),
			quantity: summary.deliveries.reduce(
				(acc, { delayedQuantity }) => (acc += Number(delayedQuantity)),
				0
			),
		};

		const divertedValue = summary.deliveries.reduce(
			(acc, { divertedValue }) => (acc += Number(divertedValue)),
			0
		);

		const diverted = {
			value: divertedValue,

			formatted: new Intl.NumberFormat('pt-BR', {
				style: 'currency',
				currency: 'BRL',
			}).format(divertedValue),
			quantity: summary.deliveries.reduce(
				(acc, { divertedQuantity }) => (acc += Number(divertedQuantity)),
				0
			),
		};

		return {
			ontime,
			delayed,
			diverted,
			total,
		};
	}, [summary.deliveries]);

	return (
		<List
			sx={{
				width: '100%',
				borderBottom: '1px solid rgba(0,0,0,0.2)',
			}}
			subheader={
				<ListSubheader
					component="div"
					sx={{
						width: '100%',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginBottom: '16px',
						gap: 4,
					}}
					onClick={() => setOpen(!open)}
				>
					<Typography fontSize="1.1rem" fontWeight="600" width="100%">
						{summary.dock}
					</Typography>

					<Chip
						label={total.formatted}
						color={total.value > 0 ? 'success' : 'error'}
					/>

					{open ? <ExpandLess /> : <ExpandMore />}
				</ListSubheader>
			}
		>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<ListItem button>
					<ListItemAvatar>
						<Badge max={9999} badgeContent={ontime.quantity} color="primary">
							<Avatar sx={{ color: '#44b86f', bgcolor: '#e1e1e1' }}>
								<TaskAlt />
							</Avatar>
						</Badge>
					</ListItemAvatar>
					<ListItemText primary="No prazo" />

					<Chip
						label={ontime.formatted}
						color={ontime.value > 0 ? 'success' : 'default'}
					/>
				</ListItem>

				<Divider />

				<ListItem button>
					<ListItemAvatar>
						<Badge max={9999} badgeContent={delayed.quantity} color="primary">
							<Avatar sx={{ color: '#ed6c02', bgcolor: '#e1e1e1' }}>
								<HourglassDisabled />
							</Avatar>
						</Badge>
					</ListItemAvatar>
					<ListItemText primary="Fora do prazo" />

					<Chip
						label={delayed.formatted}
						color={delayed.value > 0 ? 'warning' : 'default'}
					/>
				</ListItem>

				<Divider />

				<ListItem button>
					<ListItemAvatar>
						<Badge max={9999} badgeContent={diverted.quantity} color="primary">
							<Avatar sx={{ color: '#dc143c', bgcolor: '#e1e1e1' }}>
								<Error />
							</Avatar>
						</Badge>
					</ListItemAvatar>
					<ListItemText primary="Extravios" />

					<Chip
						label={diverted.formatted}
						color={diverted.value < 0 ? 'error' : 'default'}
					/>
				</ListItem>
			</Collapse>
		</List>
	);
};

export default SummaryItem;
