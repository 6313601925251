import CryptoJS from 'crypto-js';

const secret = process.env.REACT_APP_CRYPTO_KEY || null;

const isProduction = process.env.NODE_ENV === 'production';

export function encrypt(value: string) {
	if (!isProduction) {
		return value;
	}

	try {
		const ensureStringfy = value.toString();

		if (!secret) throw new Error('Encryption secret not defined');

		const ciphertext = CryptoJS.AES.encrypt(ensureStringfy, secret).toString();

		return ciphertext;
	} catch (error) {
		console.log(error);
		localStorage.clear();
		sessionStorage.clear();
		window.location.reload();
		return null;
	}
}

export function decrypt(encrypted: string) {
	if (!isProduction) {
		return encrypted;
	}
	try {
		if (!secret) throw new Error('Encryption secret not defined');

		const bytes = CryptoJS.AES.decrypt(encrypted, secret);

		const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

		return decryptedData;
	} catch (error) {
		console.log(error);
		localStorage.clear();
		sessionStorage.clear();
		window.location.reload();
		return null;
	}
}
