import { Box, Collapse, IconButton, Stack, Typography } from '@mui/material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from 'react';
import CardDetails from './CardDetails';
import { IManifest } from '../../../DTOs/IManifests';

const cardStyles = {
	width: '100%',
	maxWidth: '400px',
	background: '#fafafa',
	boxShadow:
		'0px 2px 4px rgba(0, 0, 0, 0.14), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 1px 10px rgba(0, 0, 0, 0.2)',
	borderRadius: '5px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	padding: '16px',
	position: 'relative',
};

const ManifestCard = ({ data }: { data: IManifest }) => {
	const [open, setOpen] = useState(false);

	return (
		<Box sx={cardStyles}>
			<Stack
				width="100%"
				direction="column"
				justifyContent="flex-start"
				alignContent="stretch"
				gap={1}
			>
				<Box sx={{ display: 'flex', gap: '0.25rem' }}>
					{data.driverSignedAt ? (
						<AssignmentTurnedInIcon sx={{ color: 'green' }} />
					) : (
						<AssignmentLateIcon sx={{ color: 'red' }} />
					)}
					<Typography fontSize="1rem" fontWeight="600">
						{data.code}
					</Typography>
				</Box>
				<Collapse in={!open} timeout="auto" unmountOnExit>
					<Typography fontSize="0.8rem" fontWeight="300">
						{data.driverSignedAt
							? 'Documento assinado'
							: 'Pendente de assinatura'}
					</Typography>
				</Collapse>
				<Collapse in={open} timeout="auto" unmountOnExit>
					<CardDetails manifest={data} />
				</Collapse>
			</Stack>
			<IconButton
				aria-label="expand row"
				size="small"
				onClick={() => setOpen(!open)}
				sx={{ position: 'absolute', top: '1.5rem', right: '1rem' }}
			>
				{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
			</IconButton>
		</Box>
	);
};

export default ManifestCard;
