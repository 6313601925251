import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import api from '../../../services/api';
import { queryClient } from '../../../services/queryClient';

interface ISignProps {
	manifestId: string;
	closeSignModal: (close: boolean) => void;
}

const Sign = ({ manifestId, closeSignModal }: ISignProps) => {
	const signatureCanvasRef = useRef<SignatureCanvas>(null);
	const { enqueueSnackbar } = useSnackbar();

	const handleClear = () => {
		if (signatureCanvasRef.current) {
			signatureCanvasRef.current.clear();
		}
	};

	const { mutate: requestSignMutation } = useMutation(
		async () => {
			try {
				if (signatureCanvasRef.current) {
					const signatureImage = signatureCanvasRef.current.toDataURL();
					await api.post(`manifest/${manifestId}/sign`, {
						subscriptionType: 'driver',
						base64Signature: signatureImage,
					});
				}
			} finally {
				closeSignModal(false);
				enqueueSnackbar(`Documento assinado com sucesso!`, {
					variant: 'success',
				});
			}
		},
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries(['/drivers/manifests']);
			},
		}
	);

	return (
		<Stack
			component="div"
			width="90%"
			maxWidth="400px"
			maxHeight="90%"
			display="flex"
			flexDirection="column"
			justifyContent="flex-start"
			alignItems="center"
			spacing={2}
			sx={{
				background: 'white',
				borderRadius: '8px',
				padding: 4,
			}}
		>
			<Typography fontSize="0.8rem" fontWeight="300">
				Assine aqui...
			</Typography>
			<SignatureCanvas
				ref={signatureCanvasRef}
				canvasProps={{ width: 400, height: 200, className: 'signature-canvas' }}
			/>
			<Divider sx={{ width: '100%' }} variant="middle" />

			<Box
				sx={{
					display: 'flex',
					width: '100%',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<Button variant="outlined" onClick={handleClear}>
					Limpar
				</Button>
				<Button variant="contained" onClick={() => requestSignMutation()}>
					Salvar
				</Button>
			</Box>
		</Stack>
	);
};

export default Sign;
