import { TabletAndroid } from '@mui/icons-material';
import {
	Alert,
	AlertTitle,
	Badge,
	Button,
	Chip,
	InputAdornment,
	Stack,
	TextField,
	Typography,
	useTheme,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useGeolocated } from 'react-geolocated';
import { useDispatch, useSelector } from 'react-redux';
import { IDriverPackages, IPackage } from '../../DTOs';
import Layout from '../../components/Layout';
import { useAuth } from '../../hooks/auth';
import { queryClient } from '../../services/queryClient';
import {
	addHistoryItem,
	clearHistory,
	getHistoryByType,
} from '../../store/historySlice';
import { enqueuePendingRequest } from '../../store/requestQueueSlice';
import { sendRequest } from '../../utils/driverActions';
import { logError } from '../../utils/error';
import CollectingPackageList from './CollectingPackageList';
import DockSelector from './DockSelect';
import HistoryItem from './HistoryItem';

const Collect = () => {
	const { user } = useAuth();
	const { enqueueSnackbar } = useSnackbar();
	const theme = useTheme();
	const dispatch = useDispatch();
	const collectHistory = useSelector(getHistoryByType('collect'));
	const errorsHistory = useSelector(getHistoryByType('error'));
	const [destination, setDestination] = useState<string | null>(null);

	const history = useMemo(() => {
		if (!collectHistory) return [];

		return collectHistory.sort((a, b) => {
			if (a.createAt > b.createAt) return 1;
			if (b.createAt > a.createAt) return -1;
			return 0;
		});
	}, [collectHistory]);

	const historyTotal = useMemo(
		() =>
			history.reduce((acc, curr) => {
				if (curr.type !== 'error') return acc + curr.data.length;
				return acc;
			}, 0),
		[history]
	);

	const { coords, isGeolocationAvailable, isGeolocationEnabled } =
		useGeolocated({
			positionOptions: {
				enableHighAccuracy: false,
			},
			userDecisionTimeout: 5000,
		});

	const collectInputRef = useRef<HTMLInputElement>(null);

	const handleFocusInput = () => {
		if (collectInputRef.current) {
			collectInputRef.current.focus();
		}
	};

	const { isLoading, mutate } = useMutation(
		async (code: string) => {
			if (!isGeolocationAvailable) {
				enqueueSnackbar('Localização não disponível', {
					variant: 'warning',
				});
			}

			if (!isGeolocationEnabled) {
				enqueueSnackbar('Localização bloqueada', {
					variant: 'warning',
				});
			}

			let coordinates = null as string | null;

			if (coords) {
				coordinates = `(${coords.latitude},${coords.longitude})`;
			}

			const packages = [
				{
					term: code,
					data: {
						coordinates,
					},
				},
			];

			const url = '/operations/collected';

			if (!navigator.onLine) {
				dispatch(
					enqueuePendingRequest({
						body: packages,
						userId: user.id,
						url,
					})
				);
			} else {
				try {
					await sendRequest({
						packages,
						destination,
						url,
					});

					dispatch(
						addHistoryItem({
							type: 'collect',
							data: packages,
						})
					);

					enqueueSnackbar(`Coleta realizada com sucesso!`, {
						variant: 'success',
					});
				} catch (error) {
					logError(error);
					dispatch(
						addHistoryItem({
							type: 'error',
							data: packages,
						})
					);
					enqueueSnackbar(`Erro ao realizar coleta, tente novamente!`, {
						variant: 'error',
					});
				}
			}
		},
		{
			onSettled: () => {
				if (collectInputRef.current) {
					collectInputRef.current.value = '';
				}
			},
			onSuccess: async () => {
				const collectQuery = `/drivers/${user.id}/collect`;

				if (!collectInputRef.current) throw new Error();

				const packageCode = collectInputRef.current.value;

				queryClient.setQueryData(
					[collectQuery],
					(oldPackages: IDriverPackages | undefined) =>
						oldPackages && {
							driverId: oldPackages.driverId,
							packages: oldPackages.packages.filter(
								(p) => p.alphaCode !== packageCode
							) as IPackage[],
						}
				);

				await queryClient.invalidateQueries([collectQuery]);
			},
			onError: (error) => {
				logError(error);
				enqueueSnackbar(
					`Houve um erro ao registrar a atualização do pacote, tente novamente`,
					{
						variant: 'error',
					}
				);
			},
		}
	);

	function handleCodeInputKeyDown(event: KeyboardEvent) {
		if (event.key === 'Enter') {
			if (!collectInputRef.current) throw new Error();

			const packageCode = collectInputRef.current.value;

			mutate(packageCode);
		}
	}

	const handleDockChange = (selectedDestination: string) => {
		setDestination(selectedDestination);
	};

	useEffect(() => {
		if (!isLoading) {
			handleFocusInput();
		}
	}, [isLoading]);

	if (!destination) {
		return (
			<Layout>
				<Stack
					direction="column"
					justifyContent="flex-start"
					spacing={2}
					alignItems="center"
					sx={{
						width: '100%',
						maxWidth: '400px',
						height: '100%',
						p: 2,
					}}
				>
					<DockSelector onChange={handleDockChange} />

					<Stack sx={{ width: '100%' }}>
						<Alert severity="info">
							<AlertTitle>Destino obrigatório</AlertTitle>
							Informe um <strong>Destino</strong> para as coletas
						</Alert>
					</Stack>
				</Stack>
			</Layout>
		);
	}

	return (
		<Layout>
			<Stack
				direction="column"
				justifyContent="flex-start"
				spacing={2}
				alignItems="center"
				sx={{
					width: '100%',
					maxWidth: '400px',
					height: '100%',
					p: 2,
				}}
			>
				<DockSelector onChange={handleDockChange} />

				<CollectingPackageList destination={destination} />
				<TextField
					inputRef={collectInputRef}
					disabled={isLoading}
					fullWidth
					id="collect-input"
					label="Coletar pelo código"
					variant="outlined"
					onKeyDown={handleCodeInputKeyDown}
					sx={{
						background: '#d9d9d9',
						boder: `5px solid ${theme.palette.primary.main}`,
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment
								position="end"
								sx={{ color: theme.palette.primary.main }}
							>
								<TabletAndroid />
							</InputAdornment>
						),
					}}
				/>

				{errorsHistory.length === 0 && historyTotal === 0 ? (
					<Alert severity="success">
						Nenhuma coleta no seu histórico até o momento
					</Alert>
				) : (
					<Stack
						width="100%"
						direction="column"
						justifyContent="flex-start"
						alignItems="stretch"
						spacing={1}
					>
						<Typography
							width="100%"
							textAlign="center"
							fontSize="1.2rem"
							fontWeight="600"
						>
							Histórico de coletas
						</Typography>

						<Stack
							width="100%"
							direction="row"
							justifyContent="center"
							alignItems="center"
							spacing={4}
						>
							<Badge
								color="primary"
								badgeContent={history.length}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
							>
								<Chip label="Coletas" color="success" />
							</Badge>

							<Badge
								color="primary"
								badgeContent={errorsHistory.length}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
							>
								<Chip label="Erros" color="error" />
							</Badge>
							<Badge
								color="primary"
								badgeContent={historyTotal}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
							>
								<Chip label="Pacotes" />
							</Badge>
						</Stack>
						<Button
							fullWidth
							variant="outlined"
							onClick={() => dispatch(clearHistory())}
						>
							Limpar histórico
						</Button>

						{history.map((item) => (
							<HistoryItem key={item.id} item={item} />
						))}
					</Stack>
				)}
			</Stack>
		</Layout>
	);
};

export default Collect;
