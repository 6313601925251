import {
	Alert,
	Badge,
	Box,
	Chip,
	CircularProgress,
	Stack,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import Layout from '../../components/Layout';
import { useAuth } from '../../hooks/auth';
import { logError } from '../../utils/error';
import api from '../../services/api';
import { ITransfer } from '../../DTOs/ITransfer';
import TransferCard from './TransferCard';

interface ITransferTotal {
	packagesTotal: number;
	transfers: ITransfer[];
}

const Transfer = () => {
	const { user } = useAuth();
	const { enqueueSnackbar } = useSnackbar();

	const { isLoading, data } = useQuery(
		[`/drivers/${user.id}/transfer`],
		async () => {
			const { data } = await api.get<ITransfer[]>(
				`/drivers/${user.id}/transfer`
			);

			return data;
		},
		{
			onError: (error) => {
				logError(error);
				enqueueSnackbar('Não foi possível consultar estes dados', {
					variant: 'error',
				});
			},
			networkMode: 'offlineFirst',
		}
	);

	const transferPackages = useMemo(() => {
		if (!data) return { packagesTotal: 0, transfers: [] };

		const formattedPackages = data.reduce(
			(acc, curr) => {
				acc.transfers.push(curr);
				acc.packagesTotal += curr.packages.length;

				return acc;
			},
			{ packagesTotal: 0, transfers: [] } as ITransferTotal
		);
		return formattedPackages;
	}, [data]);

	if (isLoading) {
		return (
			<Layout>
				<Box
					sx={{
						display: 'flex',
						width: '100%',
						height: '100%',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CircularProgress size="4rem" />
				</Box>
			</Layout>
		);
	}

	return (
		<Layout>
			<Stack
				direction="column"
				justifyContent="flex-start"
				spacing={2}
				alignItems="center"
				sx={{
					width: '100%',
					maxWidth: '400px',
					height: '100%',
					p: 2,
				}}
			>
				<Badge
					badgeContent={transferPackages.packagesTotal}
					max={transferPackages.packagesTotal}
					color="primary"
				>
					<Chip label="Total de pacotes em transferência" />
				</Badge>
				{data && data.length > 0 ? (
					data.map((item) => <TransferCard key={item.dock.name} item={item} />)
				) : (
					<Alert sx={{ width: '100%' }} severity="success">
						Nenhum pacote disponível no momento
					</Alert>
				)}
			</Stack>
		</Layout>
	);
};

export default Transfer;
