import { ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/auth';

interface IPrivateRouteProps {
	children: ReactElement;
}

const PrivateRoute = ({ children }: IPrivateRouteProps) => {
	const { user } = useAuth();
	const location = useLocation();

	return user ? children : <Navigate to="/login" state={location} replace />;
};

export { PrivateRoute };
