import {
	Box,
	Button,
	CircularProgress,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/img/logo-white.png';
import { useAuth } from '../../hooks/auth';
import { logError } from '../../utils/error';
import { session } from '../../utils/storage';

const Login = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const [coords, setCoords] = useState({});

	const { signIn } = useAuth();
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	useEffect(() => {
		session.clear();
		if ('geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords;
					setCoords({ latitude, longitude });
				},
				(error) => {
					logError(error);
					enqueueSnackbar(
						'O recurso de localização está bloqueado ou não é suportado.',
						{
							variant: 'error',
						}
					);
				}
			);
		}
	}, [enqueueSnackbar]);

	async function Logon() {
		setLoading(true);

		try {
			await signIn({ username, password });
			enqueueSnackbar('Usuário autenticado!', {
				variant: 'success',
			});
			navigate('/entregas');
		} catch (error) {
			logError(error);
			enqueueSnackbar(
				'Ocorreu um erro ao fazer login, cheque as credenciais.',
				{
					variant: 'error',
				}
			);
			setLoading(false);
		}
	}

	return (
		<Box
			width="100vw"
			height="100vh"
			sx={{
				background: '#1253da',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			{loading ? (
				<CircularProgress
					size="6rem"
					sx={{
						color: '#fff',
					}}
				/>
			) : (
				<Stack
					width="90%"
					height="fit-content"
					maxWidth="300px"
					sx={{
						background: '#fafafa',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-start',
						alignItems: 'center',
						borderRadius: '8px',
						padding: '16px',
					}}
					spacing="8px"
					gap="8px"
				>
					<Box width="100%" maxWidth="280px">
						<img width="100%" src={logo} alt="Vai Fácil" />
					</Box>
					<Typography fontSize="1rem" fontWeight="400" textAlign="center">
						Bem-vindo(a) ao App do Motorista.
					</Typography>
					<Typography fontSize="1rem" fontWeight="400" textAlign="center">
						Informe suas credenciais.
					</Typography>
					<TextField
						fullWidth
						placeholder="Usuário"
						type="email"
						onChange={(e) => setUsername(e.target.value)}
						value={username}
						style={{ marginTop: '10px' }}
						id="username"
						onSubmit={() => Logon()}
						disabled={Object.keys(coords).length === 0}
					/>
					<TextField
						fullWidth
						placeholder="Senha"
						type="password"
						onChange={(e) => setPassword(e.target.value)}
						value={password}
						style={{ marginTop: '10px' }}
						id="password"
						autoComplete="current-password"
						onSubmit={() => Logon()}
						disabled={Object.keys(coords).length === 0}
					/>
					<Button
						onClick={() => Logon()}
						disabled={Object.keys(coords).length === 0}
						variant="contained"
						fullWidth
					>
						Login
					</Button>
				</Stack>
			)}
		</Box>
	);
};

export default Login;
