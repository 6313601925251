import { Box, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

interface IInfoItemProps {
	title: string;
	info: string;
	additionalData?: ReactNode;
}

const InfoItem = ({ info, title, additionalData }: IInfoItemProps) => (
	<Box width="100%" textAlign="left">
		<Typography fontWeight="600" fontSize="1.2rem">
			{title}
		</Typography>
		<Typography fontSize="1rem" fontWeight="300">
			{info}
		</Typography>
		{additionalData}
	</Box>
);

InfoItem.defaultProps = {
	additionalData: null,
};

export default InfoItem;
