import { AccordionDetails, Stack } from '@mui/material';
import { useMemo } from 'react';
import { ISummaryItemData, IWalletDelivery } from '../../../../DTOs';
import SummaryItem from './SummaryItem';

const SummaryCardDetails = ({ data }: { data: IWalletDelivery[] }) => {
	const summaryByDock = useMemo(
		() =>
			data.reduce((acc, curr) => {
				const existentDock = acc.find((i) => i.dock === curr.dock);

				if (!existentDock) {
					acc.push({
						dock: curr.dock,
						deliveries: [curr],
					});
				} else {
					const index = acc.indexOf(existentDock);

					existentDock.deliveries.push(curr);

					acc.splice(index, 1, existentDock);
				}

				return acc;
			}, [] as ISummaryItemData[]),
		[data]
	);

	return (
		<AccordionDetails>
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="center"
				spacing={4}
			>
				{summaryByDock.map((summary) => (
					<SummaryItem summary={summary} key={summary.dock} />
				))}
			</Stack>
		</AccordionDetails>
	);
};

export default SummaryCardDetails;
