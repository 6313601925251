import { GlobalStylesProps } from '@mui/material';

export const global = {
	styles: {
		'*': {
			boxSizing: 'border-box',
			webkitUserSelect: 'none',
			mozUserSelect: 'none',
			fontFamily: 'Montserrat, sans-serif',
		},
		'*:focus': {
			outline: 'none',
		},
		'*::-webkit-scrollbar': {
			width: '8px',
			height: '8px',
		},
		'*::-webkit-scrollbar-thumb': {
			background: '#c4c4c4',
			borderRadius: ' 3px',
		},
		'*::-webkit-scrollbar-thumb:hover': {
			background: '#969696',
		},
		'*::-webkit-scrollbar-track': {
			background: '#d4d4d4',
			borderRadius: '4px',
			boxShadow: 'inset 7px 10px 12px #f0f0f0',
		},
		'body,html': {
			margin: '0px',
			padding: '0px',
		},
	},
} as GlobalStylesProps;
