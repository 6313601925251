/* eslint-disable jsx-a11y/media-has-caption */
import { Button, Stack } from '@mui/material';
import Compress from 'compress.js';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IDeliveryActions } from '../../../../../../DTOs';
import convertURItoBlob from '../../../../../../utils/convertURItoBlob';

interface CameraCaptureProps {
	showCameraModal: (close: boolean) => void;
	code: string;
}
const CameraCapture = ({ showCameraModal, code }: CameraCaptureProps) => {
	const compress = new Compress();

	const videoRef = useRef<HTMLVideoElement>(null);
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const [stream, setStream] = useState<MediaStream | null>(null);
	const [facingMode, setFacingMode] = useState<'user' | 'environment'>('user');

	const { setValue, getValues } = useFormContext<IDeliveryActions>();

	const captureImage = () => {
		const canvas = canvasRef.current;
		const video = videoRef.current;

		if (!video || !canvas) return;

		canvas.width = video.videoWidth;
		canvas.height = video.videoHeight;

		const context = canvas.getContext('2d');
		if (context) {
			context.drawImage(video, 0, 0, canvas.width, canvas.height);
			const image = canvas.toDataURL(`${code}`);

			const imageFile = convertURItoBlob(image, `${code}.png`);

			if (imageFile) {
				compress
					.compress([imageFile], {
						size: 1,
						quality: 0.75,
						maxWidth: 1920,
						maxHeight: 1920,
						resize: true,
					})
					.then((images) => {
						const image = images[0];
						const currentAttachments = getValues('receiver.attachments') || [];
						if (currentAttachments.length < 2) {
							const updatedAttachments = [
								...currentAttachments,
								{
									base64: image.data,
									type: imageFile.type,
									alt: image.alt,
								},
							];
							setValue('receiver.attachments', updatedAttachments);
						}
					});
			}
		}

		if (stream) {
			stream.getTracks().forEach((track) => track.stop());
		}
		showCameraModal(false);
	};

	const toggleFacingMode = () => {
		setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
	};

	useEffect(() => {
		const startCamera = async () => {
			const mediaStream = await navigator.mediaDevices.getUserMedia({
				video: { facingMode },
			});
			if (videoRef.current) {
				videoRef.current.srcObject = mediaStream;
				setStream(mediaStream);
			}
		};

		startCamera();

		return () => {
			if (stream) {
				stream.getTracks().forEach((track) => track.stop());
			}
		};
	}, [facingMode]);

	return (
		<Stack
			component="div"
			width="100%"
			maxHeight="600px"
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			spacing={2}
			sx={{
				background: 'white',
				borderRadius: '8px',
				padding: 4,
			}}
		>
			<Button onClick={toggleFacingMode}>Alternar Câmera</Button>

			<video ref={videoRef} style={{ maxWidth: '100%' }} autoPlay playsInline />
			<canvas
				ref={canvasRef}
				style={{ display: 'none' }}
				width="400"
				height="200"
			/>
			<Button onClick={captureImage}>Capturar Foto</Button>
		</Stack>
	);
};

export default CameraCapture;
