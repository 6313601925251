import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar, Badge, IconButton } from '@mui/material';
import { SyntheticEvent } from 'react';
import { IAttachments } from '../../../../../../DTOs';
import convertBase64ToFile from '../../../../../../utils/convertBase64ToFile';

interface AttachmentsBadgeProps {
	image: IAttachments;
	onDelete: (event: SyntheticEvent) => void;
}

const AttachmentsBadge = ({ image, onDelete }: AttachmentsBadgeProps) => {
	const file = convertBase64ToFile(image);

	return (
		<Badge
			overlap="circular"
			anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
			badgeContent={
				<IconButton size="small" onClick={onDelete} color="error">
					<DeleteIcon />
				</IconButton>
			}
		>
			<Avatar alt="Image thumbnail" src={URL.createObjectURL(file)} />
		</Badge>
	);
};

export default AttachmentsBadge;
