import { KeyboardArrowRight } from '@mui/icons-material';
import Warning from '@mui/icons-material/ReportProblem';
import { Box, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import '../../global.css';

interface IDeliveryCardProps {
	neighborhood: string;
	city: string;
	quantity: number;
	hasBlockDelivery: boolean;
}

const DeliveryCard = ({
	data: { city, neighborhood, quantity, hasBlockDelivery },
}: {
	data: IDeliveryCardProps;
}) => {
	const navigate = useNavigate();

	return (
		<Stack
			width="100%"
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			padding={2}
			bgcolor="#fafafa"
			borderRadius={2}
			boxShadow="3px 3px 8px 0px rgba(0,0,0,0.4)"
			onClick={() =>
				navigate(`/entregas/bairro`, {
					state: {
						neighborhood,
					},
				})
			}
			sx={{
				cursor: 'pointer',
				transition: 'transform 0.5s',
				':hover': {
					transform: 'translate(5px,-5px)',
				},
			}}
		>
			<Stack
				direction="column"
				justifyContent="space-evenly"
				alignItems="flex-start"
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						gap: 2,
					}}
				>
					{hasBlockDelivery && (
						<Warning
							color="warning"
							sx={{ fontSize: '32px', animation: 'blink 1s infinite' }}
						/>
					)}
					<Stack
						direction="column"
						justifyContent="space-evenly"
						alignItems="flex-start"
					>
						<Typography fontSize="1rem" fontWeight="600">
							{neighborhood}
						</Typography>
						<Typography fontSize="0.8rem" fontWeight="300">
							{city}
						</Typography>
					</Stack>
				</Box>
			</Stack>
			<Stack direction="row" justifyContent="center" alignItems="center">
				<Typography fontSize="0.8rem" fontWeight="300">
					{quantity} pacotes
				</Typography>
				<KeyboardArrowRight />
			</Stack>
		</Stack>
	);
};

export default DeliveryCard;
