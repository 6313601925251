import { ExpandMore, Inventory, Schedule } from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Badge,
	Stack,
	Typography,
} from '@mui/material';
import { useMemo } from 'react';
import { IHistoryItem } from '../../store/historySlice';

const HistoryItem = ({ item: { createAt, data } }: { item: IHistoryItem }) => {
	const formattedDate = useMemo(
		() => new Date(createAt).toLocaleString('pt-BR'),
		[createAt]
	);

	return (
		<Accordion
			sx={{
				width: '100%',
			}}
		>
			<AccordionSummary expandIcon={<ExpandMore />}>
				<Stack
					width="100%"
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
					spacing={1}
				>
					<Schedule />
					<Typography
						fontSize="1rem"
						fontWeight="600"
						sx={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						{formattedDate}
					</Typography>

					<Badge
						color="primary"
						badgeContent={data.length}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
					>
						<Inventory color="primary" />
					</Badge>
				</Stack>
			</AccordionSummary>
			<AccordionDetails>
				<Stack
					width="100%"
					direction="column"
					justifyContent="flex-start"
					alignItems="center"
					spacing={2}
				>
					{data.map((pkg) => (
						<Typography
							width="100%"
							key={pkg.term}
							fontSize="1.2rem"
							fontWeight="600"
							color="text.secondary"
							textAlign="center"
							sx={{
								lineBreak: 'anywhere',
								wordBreak: 'break-all',
								lineHeight: '1.5rem',
							}}
						>
							{pkg.term}
						</Typography>
					))}
				</Stack>
			</AccordionDetails>
		</Accordion>
	);
};

export default HistoryItem;
