import { Stack } from '@mui/material';
import React from 'react';

const OfflineWarning = () => (
	<Stack
		width="100vw"
		height="15px"
		bgcolor="crimson"
		color="white"
		fontWeight="600"
		fontSize="0.8rem"
		textTransform="uppercase"
		position="absolute"
		zIndex={99999}
		top="0px"
		left="0px"
		right="0px"
		textAlign="center"
		sx={{
			opacity: 0.7,
			borderRadius: '0px 0px 24px 24px',
		}}
	>
		Offline
	</Stack>
);

export default OfflineWarning;
